<template>
  <div class="page-wrapper">
    <home-hero></home-hero>
    <main id="main">
      <!-- ======= Featured Services Section ======= -->
      <hr />
      <home-featured-services></home-featured-services>
      <!-- End Featured Services Section -->

      <!-- ======= Cta Section ======= -->
      <hr />
      <home-cta></home-cta>
      <!-- End Cta Section -->

      <!-- ======= Contact Section ======= -->
      <hr />
      <contact-form></contact-form>
      <!-- End Contact Section -->
    </main>
    <ks-footer></ks-footer>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm'
import HomeCta from '@/components/home/Cta'
import HomeFeaturedServices from '@/components/home/FeaturedServices'
import HomeHero from '@/components/home/Hero'
import KsFooter from '@/components/KsFooter'
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'Ventilators, LED, Industrial Manufacturers in India'
      },
      meta: [
        {
          name: 'description',
          content:
            'Specialized Manufacturers and Innovation specialists in machinery, automation and low cost manufacturing in India. Indigenous ventilators, automobile spares.',
          id: 'desc'
        },
        {
          name: 'keywords',
          content: `ventilators, automobile parts, Covid ventilators, low cost manufacturing india, outsource manufacturing, machine components`
        },
        {
          property: 'og:title',
          content: 'Ventilators, LED, Industrial Manufacturers in India'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:description',
          content:
            'Specialized Manufacturers and Innovation specialists in machinery, automation and low cost manufacturing in India. Indigenous ventilators, automobile spares.'
        },
        {
          property: 'og:image',
          itemprop: 'image',
          content: 'https://kseng.co.in/img/icons/android-chrome-192x192.png'
        }
      ],
      link: [
        {
          href: 'https://maps.gstatic.com',
          rel: 'preconnect'
        }
      ]
    }
  },
  components: {
    ContactForm,
    HomeCta,
    HomeFeaturedServices,
    HomeHero,
    KsFooter
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
