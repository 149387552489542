import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
import '@/firebase/init'
import '@/misc/handle-apple-install-prompt'
import 'pwacompat'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import VueAnalytics from 'vue-analytics'

import store from './store'
import router from './router'
import App from './App.vue'

dom.watch()

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Buefy)

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-146147022-2',
  router
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
