<template>
  <div class="page-wrapper">
    <sevak-hero></sevak-hero>

    <main id="main">
      <!-- ======= Video Section ======= -->
      <hr />
      <sevak-video></sevak-video>
      <!-- End Video Section -->

      <!-- ======= Made in India Section ======= -->
      <hr />
      <sevak-made-in-india></sevak-made-in-india>
      <!-- End made in india Section -->

      <!-- ======= Proven Technology Section ======= -->
      <hr />
      <sevak-technology></sevak-technology>
      <!-- End technology Section -->

      <!-- ======= Search keyword Section ======= -->
      <hr />
      <sevak-search-keyword></sevak-search-keyword>
      <!-- End Search keyword Section -->

      <!-- ======= Team Section ======= -->
      <hr />
      <sevak-team></sevak-team>
      <!-- End Team Section -->

      <!-- ======= Core Functionality Section ======= -->
      <hr />
      <sevak-core-functionality></sevak-core-functionality>
      <!-- End Core functionality Section -->

      <!-- ======= Innovation Section ======= -->
      <hr />
      <sevak-innovation-section></sevak-innovation-section>
      <!-- End Innovation Section -->

      <!-- ======= Contact Section ======= -->
      <hr />
      <contact-form></contact-form>
      <!-- End Contact Section -->
    </main>
    <ks-footer></ks-footer>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm'
import KsFooter from '@/components/KsFooter'
import SevakCoreFunctionality from '@/components/sevak/CoreFunctionality'
import SevakHero from '@/components/sevak/Hero'
import SevakInnovationSection from '@/components/sevak/InnovationSection'
import SevakMadeInIndia from '@/components/sevak/MadeInIndia'
import SevakSearchKeyword from '@/components/sevak/SearchKeyword'
import SevakTeam from '@/components/sevak/Team'
import SevakTechnology from '@/components/sevak/Technology'
import SevakVideo from '@/components/sevak/Video'
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'SEVAK Emergency Covid Ventilator Hospitals India'
      },
      meta: [
        {
          name: 'description',
          content: `Sevak is a portable reliable ventilator with powerful controls. Featuring mobile monitoring, easy working and meets medical standards. Enquire today!`,
          id: 'desc'
        },
        {
          name: 'keywords',
          content:
            'covid, covid ventilator, ventilator india, ventilator in india, coronavirus ventilator, ventilators, Covid ventilators, low cost manufacturing india, outsource manufacturing'
        },
        {
          property: 'og:title',
          content: 'SEVAK Emergency Covid Ventilator Hospitals India'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:description',
          content:
            'Sevak is a portable reliable ventilator with powerful controls. Featuring mobile monitoring, easy working and meets medical standards. Enquire today!'
        },
        {
          property: 'og:image',
          itemprop: 'image',
          content: 'https://kseng.co.in/img/s-evak.jpeg'
        }
      ],
      link: [
        {
          href: 'https://maps.gstatic.com',
          rel: 'preconnect'
        }
      ]
    }
  },
  components: {
    ContactForm,
    SevakCoreFunctionality,
    SevakHero,
    SevakInnovationSection,
    SevakMadeInIndia,
    SevakSearchKeyword,
    SevakTeam,
    SevakTechnology,
    SevakVideo,
    KsFooter
  },
  computed: mapState('app', ['appTitle'])
}
</script>
